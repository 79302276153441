import '../../assets/css/login/login.css';
import React, { Component } from 'react';
import { connect} from "react-redux";
import {adCall, ticketPrintCall} from "../../actions";
import {eventConstants, globalConstants, globalData} from "../../constants";
import {Linear, Power4, TweenMax} from "gsap";
import {ReactComponent as RatingIcon} from "../../assets/img/rating.svg";
import RatingButton from "./RatingButton";
import adAction from "../../actions/adAction";
import axios from "axios";
import * as utils from "../../constants/utils";
import {cloneDeep} from "lodash";
import {appendDeviceId, translateText} from "../../constants/utils";
import {translateTextPopup} from "../../constants/utils";

class Rate extends Component {

    constructor(props){
        super(props);
        this.theComponent = React.createRef();

        this.mainInterval = null;
        this.secondInterval = null;

        this.hideTimeout = null;
        this.screenShown = false;
        this.ticketObj = {};
        this.ticketLocal = -1;
    }

    componentDidMount() {
        this.startMainInterval();
        this.startSecondInterval();

        window.addEventListener('resize', this.resize);
        this.forceUpdate();
    }

    resize = () => this.forceUpdate();

    forceUpdate(){
        if(this.theComponent.current){
           /* this.theComponent.current.style.height = ((window.innerHeight -
                document.querySelector(".heading-content").offsetHeight) + "px");*/
        }
    }

    startMainInterval(){
        let {loginState, adStateDetect, adAction} = this.props;

        this.mainInterval = setInterval(()=>{
            if (loginState.config.subtype === "rate") {
                if(this.screenShown === false){
                    let postUrl = loginState.config.ticket + loginState.loginAuth;
                    postUrl = appendDeviceId(postUrl, loginState.deviceId);
                    axios.post(postUrl, {})
                        .then(response => {
                            if (response.data.request.status === "ok") {
                                if (!utils.isEqual(response.data.response, this.ticketObj)) {
                                    this.ticketObj = cloneDeep(response.data.response);
                                    if(this.ticketObj.hasOwnProperty("ticket")){
                                        this.ticketLocal = this.ticketObj.ticket;
                                    }
                                }
                            }else {
                                adAction({
                                    type: eventConstants.API_LIGHT_FAIL
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log("  THIS IS ONE ERROR !!!!!");
                            console.log(error);

                            adAction({
                                type: eventConstants.API_LIGHT_FAIL,
                                failResponse: error
                            });

                        });
                }
            }
        }, loginState.config.ticket_refresh * 1000);
    }

    startSecondInterval(){
        let {loginState, adStateDetect, adAction} = this.props;

        this.secondInterval = setInterval(()=>{
            if (loginState.config.subtype === "rate") {
                if(this.screenShown === false){
                    if(this.ticketLocal !== -1){
                        let postUrl = loginState.config.ticket_info + loginState.loginAuth + "&ticket=" + this.ticketLocal;
                        postUrl = appendDeviceId(postUrl, loginState.deviceId);
                        axios.post(postUrl, {})
                            .then(response => {
                                if (response.data.request.status === "ok") {
                                    if(response.data.response.ticket === this.ticketLocal){
                                        if(response.data.response.status === 7){
                                            this.showRatingScreen();
                                        }
                                    }
                                } else {
                                    adAction({
                                        type: eventConstants.API_LIGHT_FAIL
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log("  THIS IS ONE ERROR !!!!!");
                                console.log(error);

                                adAction({
                                    type: eventConstants.API_LIGHT_FAIL,
                                    failResponse: error
                                });

                            });
                    }
                }
            }
        }, loginState.config.ticket_refresh * 1000);
    }

    showRatingScreen(){
        let {loginState, adStateDetect, adAction} = this.props;
        this.screenShown = true;
        this.myTween = TweenMax.to(this.theComponent.current, 1, {autoAlpha: 1, pointerEvents:"auto", ease: Power4.easeNone});

        adAction({
            type: eventConstants.SHOW_RATING_SCREEN,
            ticket: this.ticketLocal
        });

        this.hideTimeout = setTimeout(()=>{
            adAction({
                type: eventConstants.HIDE_RATING_SCREEN
            });
            this.hideRatingScreen();
        }, loginState.config.rate_stay * 1000)
    }

    hideRatingScreen(){
        clearTimeout(this.hideTimeout);
        this.screenShown = false;
        this.myTween = TweenMax.to(this.theComponent.current, 1, {autoAlpha: 0, ease: Power4.easeNone});
        this.ticketLocal = -1;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {loginState, adStateDetect, adAction} = this.props;
        if(adStateDetect.rateTicket === -10){
            if(this.screenShown === true){
                this.hideRatingScreen();
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
        clearInterval(this.mainInterval);
        clearInterval(this.secondInterval);
    }

    render() {
        let {loginState, adStateDetect} = this.props;

        if (loginState.config.subtype === "rate") {

            return (
                <div className="landscape">
                    <div className="smartq-rating-holder">
                        <div className="smartq-rating" ref={this.theComponent}>
                            <div className="smartq-rating-content">
                                <div className="rating-info">
                                    <div className="rating-number">{adStateDetect.ticket}</div>
                                    <div className="operator-info">
                                        <div className="info">{loginState.language[loginState.currentLang]["ads"]["rate"].texts.served}</div>
                                        <div className="operator">{loginState.config.operator_name}</div>
                                    </div>
                                </div>

                                <div className="rating-box">
                                    <div className="invite-to-rate">{loginState.language[loginState.currentLang]["ads"]["rate"].texts.text}</div>
                                    <div className="rate-buttons">
                                        <RatingButton rateScore={1} />
                                        <RatingButton rateScore={2} />
                                        <RatingButton rateScore={3} />
                                        <RatingButton rateScore={4} />
                                        <RatingButton rateScore={5} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    adStateDetect: state.adStateDetect,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    adAction
};


export default connect(mapStateToProps, mapDispatchToProps)(Rate);

import {eventConstants, globalConstants, operatorPopupConstants, serverFields} from "../constants";
import axios from "axios";
import {buildUrlFormat, isEmpty, appendDeviceId} from "../constants/utils";

export const API = store => next => action => {
    if (action.type === globalConstants.API) {
        middlewareName(action);
        let payload = action.payload;

        payload.url = appendDeviceId(payload.url, store.getState().loginState.deviceId);
        axios.post(payload.url, {})
            .then(response => {
                console.log(response);
                if ((response.data.request.status === "ok") || (response.data.request.request === "auth")) {
                    let newAction = {
                        type: payload.success,
                        payload: {
                            deepPayload: {...payload},
                            ...response.data,
                            calledUrl: payload.url
                        }
                    };
                    store.dispatch(newAction);
                } else {
                    store.dispatch({
                        type: payload.fail,
                        payload: {
                            failResponse: response.data
                        }
                    });
                }
            })
            .catch(function (error) {

                console.log("  THIS IS ONE ERROR !!!!!");

                console.log(error);
                console.log(error.response);
                console.log("  ");
                store.dispatch({
                    type: payload.fail,
                    payload: {
                        failResponse: error
                    }
                });
            });

    } else if (action.type === eventConstants.OPERATOR_SERVER_CALL) {
        middlewareName(action);
        let payload = action.payload;

        console.log(" payload: ");
        console.log(payload);
        let allowServerCall = false;

        if (payload.popupRequired === 1) {
            if (payload.popupConfirmed === 0) {
                let newAction = {
                    type: payload.popup,
                    payload: {
                        ...payload
                    }
                };

                store.dispatch(newAction);
            } else if (payload.popupConfirmed === 1) {
                if(payload.popupSubType === operatorPopupConstants.POPUP_RECALL){
                    setTimeout(() => {
                        let newAction = {
                            type: eventConstants.ENABLE_RECALL,
                            payload: {
                                ...payload
                            }
                        };
                        store.dispatch(newAction);
                    }, payload.timeoutInterval);
                }

                allowServerCall = true;
            } else if (payload.popupConfirmed === -1) {
                let newAction = {
                    type: operatorPopupConstants.POPUP_ACTION_CANCEL,
                    payload: {
                        ...payload
                    }
                };
                store.dispatch(newAction);
            } else {
                return next(action);
            }
        } else {
            allowServerCall = true;
        }
        if (allowServerCall === true) {
            if(!isEmpty(payload.note)){
                payload.url += "&note=" + payload.note;
            }
            payload.url = appendDeviceId(payload.url, store.getState().loginState.deviceId);
            axios.post(payload.url, {})
                .then(response => {

                    let newAction = {
                        type: payload.success,
                        payload: {
                            deepPayload:
                                {
                                    ...payload
                                },
                            ...response.data
                        }
                    };
                    store.dispatch(newAction);


                })
                .catch(function (error) {
                    console.log(error);
                    let newAction = {
                        type: action.payload.fail,
                        payload: error
                    };
                    store.dispatch(newAction);
                    next(action);
                });
        }
    } else if (action.type === eventConstants.SUCCESS_PING) {

        let {loginState} = store.getState();
        if (action.payload.request.status === serverFields.OK) {
            let callUrl = "";
            if (!isEmpty(loginState.serverAddress)) {
                localStorage.setItem(globalConstants.LOCAL_STORAGE_OBJ_PING, loginState.serverAddress);
                callUrl = loginState.serverAddress;
            } else if (!isEmpty(loginState.ping)) {
                callUrl = loginState.ping;
            }

            let newAction = {
                type: globalConstants.API,
                payload: {
                    success: eventConstants.SUCCESS_BLANK_DETECT,
                    fail: eventConstants.FAIL_DETECT,
                    url: buildUrlFormat(callUrl) + globalConstants.API_DETECT,
                    API_BASE: buildUrlFormat(callUrl)
                }
            };
            store.dispatch(newAction);
        }
    } else if (action.type === eventConstants.OPERATOR_ACTION) {
        middlewareName(action);
        let payload = action.payload;

        console.log(" payload: ");
        console.log(payload);
        let allowAction = false;

        if (payload.popupRequired === 1) {
            if (payload.popupConfirmed === 0) {
                let newAction = {
                    type: payload.popup,
                    payload: {
                        ...payload
                    }
                };

                store.dispatch(newAction);
            } else if (payload.popupConfirmed === 1) {
                allowAction = true;
            } else if (payload.popupConfirmed === -1) {
                let newAction = {
                    type: operatorPopupConstants.POPUP_ACTION_CANCEL,
                    payload: {
                        ...payload
                    }
                };
                store.dispatch(newAction);
            } else {
                return next(action);
            }
        } else {
            allowAction = true;
        }
        if (allowAction === true) {
            let newAction = {
                type: payload.success,
                payload: {
                    deepPayload:
                        {
                            ...payload
                        }
                }
            };
            store.dispatch(newAction);
        }
    } else {
        next(action);
    }
};

const middlewareName = (action) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- MIDDLEWARE: API     ", action.type);
        console.log("action:  ", action);
    }
};

import React, {Component, Fragment} from 'react';
import { connect } from "react-redux";
import "../../assets/css/ticket-counter/ticket-counter.css";
import "../../assets/css/ticket-counter/ticket-counter-positioning.css";
import {eventConstants, globalConstants} from "../../constants";
import queueAction from "../../actions/queueAction";
import axios from "axios";
import * as utils from "../../constants/utils";
import {cloneDeep} from "lodash";
import QueueList from "./QueueList";
import NewsFeed from "../newsfeed/NewsFeed";
import QueueCallItem from "./QueueCallItem";
import adAction from "../../actions/adAction"
import MediaItem from "../ad/MediaItem";
import PopupLogout from "../Popup/PopupLogout";
import EventBus from "../../constants/EventBus";
import {appendDeviceId, isEmpty} from "../../constants/utils";


class Queue extends Component {
    constructor(props) {
        super(props);
        this.counterKey = 0;
        // DEV emanuel razvan
        /*let localData = localStorage.getItem("FUCKING_Q_COUNTER");

        if(!isEmpty(localData)){
            this.counterKey = parseInt(localData);
        }
*/
        localStorage.setItem("FUCKING_Q_COUNTER", parseInt(this.counterKey) + 1000);
        this.mainInterval = null;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.queueState.actionMode === true){
            return false;
        } else {
            return true;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {loginState, queueState, queueAction} = this.props;
        if(queueState.actions.length > 0 && queueState.actionMode === false){
            queueAction({
                type: eventConstants.EXECUTE_NEXT_QUEUE_ACTION
            });
        }
    }

    startMainInterval() {
        let {loginState, queueAction} = this.props;

        this.mainInterval = setInterval(() => {
            let {queueState} = this.props;

            if(queueState.blockRequests === false){
                this.counterKey++;
                let postUrl = loginState.config.queue + loginState.loginAuth + "&key=" + this.counterKey
                postUrl = appendDeviceId(postUrl, loginState.deviceId);
                axios.post(postUrl, {})
                    .then(response => {
                        if (response.data.request.status === "ok") {

                            if (!utils.isEqual(response.data.response, queueState.currentData)) {
                                if (response.data.response.length > 0 && queueState.currentData.length >= 1) {
                                    let newData = cloneDeep(response.data.response);
                                    queueAction({
                                        type: eventConstants.UPDATE_QUEUE_DATA,
                                        currentData: newData
                                    });
                                }

                            } else {
                                if(response.data.response.length === 0){
                                    if(queueState.emptyMode === true){
                                        queueAction({
                                            type: eventConstants.UPDATE_QUEUE_DATA_EMPTY
                                        });
                                    }
                                }

                            }

                        } else {
                            queueAction({
                                type: eventConstants.API_LIGHT_FAIL
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log("  THIS IS ONE ERROR !!!!!");
                        console.log(error);

                        queueAction({
                            type: eventConstants.API_LIGHT_FAIL,
                            failResponse: error
                        });

                    });
            }
        }, loginState.config.queue_refresh * 1000);
    }

    componentDidMount() {
        let {queueAction, loginState, adAction} = this.props;

        queueAction({
            type: globalConstants.API,
            success: eventConstants.QUEUE_SKIN_SUCCESS,
            fail: eventConstants.API_LIGHT_FAIL,
            url: loginState.visual.skin.link + loginState.loginAuth
        });


        adAction({
            type: eventConstants.TIMER_START,
            timerName: "ads_feed",
            timerInterval: loginState.config.ads_refresh * 1000,
            secondCall:{
                callType: eventConstants.TIMER_API,
                success: eventConstants.AD_SERVER_CALL_SUCCESS,
                fail: eventConstants.API_LIGHT_FAIL,
                url: loginState.config.ads_feed + loginState.loginAuth,
            }
        });
    }

    renderLists() {
        let {loginState, queueState, queueAction} = this.props;

        if (queueState.currentData.length > 0) {

            let theLists = queueState.skins[loginState.devSkin].lists;

            let dataIdx = 0;
            let cnt = 0;

            let customLists = [];
            let idx;
            let k;

            for (idx = 0; idx < theLists.length; idx++) {
                customLists.push({
                    tickets: []
                });
                cnt = 0;

                for (dataIdx; dataIdx < queueState.currentData.length; dataIdx++) {
                    if (queueState.currentData[dataIdx].status !== "pending") {
                        customLists[idx].tickets.push(queueState.currentData[dataIdx]);
                        cnt++;
                    }
                    if (cnt >= theLists[idx].tickets.length) {
                        dataIdx++;
                        break;
                    }
                }
            }

            let wrongPrioCallItems = [];

            for (idx = 0; idx < customLists.length; idx++) {
                for (dataIdx = 0; dataIdx < customLists[idx].tickets.length; dataIdx++) {
                    if(customLists[idx].tickets[dataIdx].status === "calling" || customLists[idx].tickets[dataIdx].status === "recall"){
                        if(queueState.skins[loginState.devSkin].call.list.min_prio !== theLists[idx].prio){
                            wrongPrioCallItems.push({
                                item: cloneDeep(customLists[idx].tickets[dataIdx]),
                                prio: theLists[idx].prio
                            });
                            delete customLists[idx].tickets[dataIdx];
                        }
                    }
                }
            }

            for (idx = 0; idx < customLists.length; idx++) {
                for (dataIdx = 0; dataIdx < customLists[idx].tickets.length; dataIdx++) {
                    if(!customLists[idx].tickets[dataIdx]){
                        customLists[idx].tickets.splice(dataIdx, 1);
                        dataIdx--;
                    }
                }
            }



            let prioNow;

            for (k = 0; k < wrongPrioCallItems.length; k++) {
                prioNow = queueState.skins[loginState.devSkin].call.list.min_prio;
                for (idx = 0; idx < customLists.length; idx++) {
                    if (prioNow === theLists[idx].prio) {
                        if(customLists[idx].tickets.length < theLists[idx].tickets.length){
                            if(queueState.skins[loginState.devSkin].call.list.pos === "bottom"){
                                customLists[idx].tickets.push(wrongPrioCallItems[k]);
                            }else{
                                customLists[idx].tickets.unshift(wrongPrioCallItems[k]);
                            }
                        } else{
                            prioNow++;
                        }
                    }
                }
            }



            dataIdx = 0;
            for (idx = 0; idx < theLists.length; idx++) {
                cnt = customLists[idx].tickets.length;
                if (theLists[idx].pending === true) {
                    for (dataIdx; dataIdx < queueState.currentData.length; dataIdx++) {
                        if (queueState.currentData[dataIdx].status === "pending") {
                            customLists[idx].tickets.push(queueState.currentData[dataIdx]);
                            cnt++;
                        }
                        if (cnt >= theLists[idx].tickets.length) {
                            dataIdx++;
                            break;
                        }
                    }
                }
            }

            console.log(" MY CUSTOM LIST: ", this.counterKey);
            console.log(customLists);
            for (idx = 0; idx < customLists.length; idx++) {
                console.log(" LIST: ", idx, "EMPTY: ", customLists[idx].tickets.length === 0);
                for (dataIdx = 0; dataIdx < customLists[idx].tickets.length; dataIdx++) {
                    console.log(dataIdx, " ", customLists[idx].tickets[dataIdx]);
                }
            }



            return (
                <div className="ticket-counter">
                    <QueueCallItem />
                    {customLists.map((item, i) =>
                        <QueueList key={i} tickets={item} idx={i}/>
                    )}
                    {this.renderImgs()}
                </div>
            )
        } else {
            if((queueState.skins !== null) && (this.mainInterval === null)){
               this.startMainInterval();
            }
            return null;
        }
    }

    renderImgs(){
        let { adStateDetect, queueState, loginState} = this.props;

        if(adStateDetect.adItemsAr.length > 0){
            let mySettings = null;
            if(queueState.skins !== null){
                mySettings = queueState.skins[loginState.devSkin].ads;
            }

            if(mySettings){
                if(mySettings.show === true){
                    EventBus.getInstance().fireEvent("ADD_CLASS_TO_MAIN_SECOND_STAGE", {
                        className: mySettings.cssClass.app
                    });
                }
            }

            return(
                <div className="ads" className={mySettings.cssClass.ads}>
                    {adStateDetect.adItemsAr.map((item, key) =>
                        <MediaItem key={key}
                                   idx={key}
                                   item={item}
                                   current={adStateDetect.current}
                                   previous={adStateDetect.previous}
                                   skinSettings={mySettings}
                                   totalItems={adStateDetect.adItemsAr.length}
                        />
                    )}
                </div>
            )
        }
        return null;
    }
    render() {
        let {loginState, queueState} = this.props;

        let mySettings = null;
        if(queueState.skins !== null){
            mySettings = queueState.skins[loginState.devSkin].news;

            EventBus.getInstance().fireEvent("ADD_CLASS_TO_MAIN_SECOND_STAGE", {
                className: queueState.skins[loginState.devSkin].cssClass.app
            });
        }






        return (
            <Fragment>

                {this.renderLists()}

                <NewsFeed firstCssClass="news-section tc-news-section" secondCssClass="news-content" skinSettings={mySettings}/>

                <PopupLogout/>
            </Fragment>

        );
    }


    componentWillUnmount() {
        let {queueAction, adAction} = this.props;
        adAction({
            type: eventConstants.TIMER_STOP,
            timerName: "ads_feed"
        });
        clearInterval(this.mainInterval);
    }
}

const mapStateToProps= (state) => ({
    loginState: state.loginState,
    queueState: state.queueState,
    adStateDetect: state.adStateDetect
});

const mapDispatchToProps =  {
    queueAction,
    adAction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Queue);
import {eventConstants, globalConstants, globalData, serverFields} from "../constants";
import {getLocalUserData, buildUrlFormat, isEmpty, create_UUID, getDeviceId} from "../constants/utils"


const initialState = {
    user: getLocalUserData(globalConstants.LOCAL_STORAGE_OBJ_USER),
    pass: getLocalUserData(globalConstants.LOCAL_STORAGE_OBJ_PASS),
    ping: getLocalUserData(globalConstants.LOCAL_STORAGE_OBJ_PING),
    ukey: "",
    pkey: "",
    loginAuth: "",

    deviceId: getDeviceId(),

    loggedIn: false,
    formValid: false,

    screen: eventConstants.LAUNCH_LOGIN,
    subScreen: "",
    serverData: "",

    serverAddress: getLocalUserData("ping"),

    showErrorPopup: false,
    showExitPopup: false,
    confirmPopupSubType: "",
    shown: false,

    API_BASE: "",
    pingUrl: "",
    detectUrl: "",
    userPassAuth: "",
    auth: {},
    config: {},
    response: [],
    language: {},
    visual: {},
    currentLang: "",
    externalCssLoaded: false,
    externalCssContent: "",
    devSkin: "" //skin-line-vertical-line-horizontal-call-fullscreen
};

const loginState = (state = initialState, action) => {
      switch(action.type) {

          case eventConstants.POPUP_ACTION_EXIT_SUCCESS:
              reducerName(state, action);

              // emanuel
              console.log(" EXIT !!!!!!! ");

              if (window.cordova){
                  navigator.app.exitApp();
              }

              return state;

          case eventConstants.SUCCESS_CUSTOM_CSS:
              reducerName(state, action);

              return {
                  ...state,
                  externalCssLoaded: true,
                  externalCssContent: action.payload.response
              };
          case eventConstants.CHANGE_LANGUAGE:
              reducerName(state, action);

              return {
                  ...state,
                  currentLang: action.payload.lang
              };

          case eventConstants.SWITCH_TRIGGERED:
              reducerName(state, action);

              return {
                  ...state,
                  config: {
                      ...state.config,
                      type: action.payload.val
                  },
                  screen: eventConstants.LAUNCH_SCREEN_TICKET
              };

        case eventConstants.STORE:
             return {
                ...state,
                serverAddress: action.payload.val
            };


        case globalConstants.VALIDATE_SEND_FORM:

            return Object.assign({}, state, {
                formValid: action.payload.formValid
            });

        case eventConstants.FORM_CHANGE:
            reducerName(state, action);

            return {
                ...state,
                user: action.payload.field === "user" ? action.payload.val : state.user,
                pass: action.payload.field === "pass" ? action.payload.val : state.pass
            };

          case eventConstants.HIDE_LOGIN_POPUP:
              reducerName(state, action);
              return {
                  ...state,
                  showErrorPopup: false,
                  shown: false
              };

          case eventConstants.HIDE_LOGIN_ERR_POPUP:
              reducerName(state, action);
              return {
                  ...state,
                  showExitPopup: false,
                  showErrorPopup: false,
                 shown: false
              };

          case eventConstants.SHOW_EXIT_CONFIRMATION:
              reducerName(state, action);
                   return {
                      ...state,
                       showExitPopup: true,
                      showErrorPopup: false,
                      confirmPopupSubType: "exit",
                      shown: true
                  };


          case eventConstants.SUCCESS_DETECT:
              reducerName(state, action);
              if(action.payload.request.status === "error"){
                  localStorage.setItem(globalConstants.LAST_LOGIN_SUCCESS, false);
                  return {
                      ...state,
                      showErrorPopup: true,
                      showExitPopup: false,
                      confirmPopupSubType: "login_error",
                      shown: true
                  };
              }else{
                  localStorage.setItem(globalConstants.LAST_LOGIN_SUCCESS, true);
                  localStorage.setItem(globalConstants.LOCAL_STORAGE_OBJ_USER, state.user);
                  localStorage.setItem(globalConstants.LOCAL_STORAGE_OBJ_PASS, state.pass);
              }

              let newScreen = "";
              if (action.payload.config.type === "ticket") {
                  newScreen = eventConstants.LAUNCH_SCREEN_TICKET;
              } else if (action.payload.config.type === "operator") {
                  newScreen = eventConstants.LAUNCH_OPERATOR;
              } else if (action.payload.config.type === "ads") {
                  newScreen = eventConstants.LAUNCH_ADS;
              } else if (action.payload.config.type === "queue") {
                  newScreen = eventConstants.LAUNCH_QUEUE;
              }


            return {
                ...state,
                auth: {...action.payload.request},
                config: {...action.payload.config},
                response: {...action.payload.response},
                language: {...action.payload.language},
                visual:{...action.payload.visual},
                currentLang: action.payload.config.lang,
                ukey: action.payload.auth.ukey,
                pkey: action.payload.auth.pkey,
                loginAuth: "?ukey=" + action.payload.auth.ukey + "&pkey=" + action.payload.auth.pkey,
                screen: newScreen,
                loggedIn: true,
                devSkin: action.payload.config.type === "queue" ? action.payload.visual.skin.current : ""
            };

        case eventConstants.SUCCESS_BLANK_DETECT:
            if(action.payload.request.status === serverFields.ERROR){
                if(action.payload.request.request === serverFields.AUTH){
                    reducerName(state, action);
                    return{
                        ...state,
                        API_BASE: action.payload.deepPayload.API_BASE,
                        subScreen: eventConstants.LAUNCH_LOGIN,
                        auth:{...action.payload.request},
                        config:{...action.payload.config},
                        response:{...action.payload.response},
                        language:{...action.payload.language},
                        currentLang: action.payload.config.lang
                    };
                }
            }

        case eventConstants.SUCCESS_PING:
            if(action.payload.request.status === serverFields.OK){
                reducerName(state, action);
                if(!isEmpty(state.serverAddress)){
                    localStorage.setItem(globalConstants.LOCAL_STORAGE_OBJ_PING, state.serverAddress);
                }

                let api_base = "";
                if (!isEmpty(state.serverAddress)) {
                    api_base = state.serverAddress;
                } else if (!isEmpty(state.ping)) {
                    api_base = state.ping;
                }

                return{
                    ...state,
                    API_BASE: api_base,
                    subScreen: eventConstants.LAUNCH_LOGIN
                };
            } else{
                return{
                    state
                }
            }


        case eventConstants.FAIL_PING:
            reducerName(state, action);
            console.log(" FAIL ! ");

            return {
                ...state,
                subScreen: eventConstants.LAUNCH_SETUP
            };

        case eventConstants.LAUNCH_SETUP:
            reducerName(state, action);
            return {
                ...state,
                subScreen: eventConstants.LAUNCH_SETUP
            };

        case eventConstants.LOGOUT_SUCCESS:
            reducerName(state, action);
            localStorage.removeItem(globalConstants.LOCAL_STORAGE_OBJ_USER)
            localStorage.removeItem(globalConstants.LOCAL_STORAGE_OBJ_PASS)
            localStorage.removeItem(globalConstants.LAST_LOGIN_SUCCESS);
            return {
                ...state,
                ...initialState,
                user: "",
                pass: ""
            };

        case eventConstants.SUCCESS:
            reducerName(state, action);
            if(action.payload.subType === eventConstants.SUCCESS_PING){
                return {
                    state,
                    pingUrl: action.payload.url,
                    detectUrl: action.payload.url + "/smartq/json/detect"
                };
            }

            return state;


        default:
            return state;
    }
};

const reducerName = (state, action) => {
    if(globalConstants.DEV_ENABLE_LOG === true){
        console.log("--- REDUCER: loginState     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
    }
};

export default loginState;
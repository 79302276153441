import '../../assets/css/login/login.css';
import React, { Component } from 'react';
import { TimelineMax, TweenMax, Linear, Power0, Elastic } from 'gsap';
import { connect} from "react-redux";
import {eventConstants} from "../../constants";
import NewsFeedItem from "./NewsFeedItem";
import newsfeedAction from "../../actions/newsfeed.action"
import popupLogoutAction from "../../actions/popupLogout.action";
import {isEmpty} from "../../constants/utils";
import EventBus from "../../constants/EventBus";


class NewsFeed extends Component {

    constructor(props){
        super(props);

        this.loading = false;
        this.headingContent = React.createRef();
        this.tickerWrapper = React.createRef();
        this.myItem = React.createRef();
        this.myItemCloned = React.createRef();
        this.heading = React.createRef();
        this.tickerObj = {};
        this.myTween = null;
        this.animTimeOut = null;

        this.timeInterval = null;

        this.clickTick = 0;
        this.clickTimeout = null;

        this.enabled = true;
    }

    componentDidMount() {
        let {loginState} = this.props;
        let {newsfeedAction} = this.props;
        if(loginState.config.ticker === null){
            return;
        }
        if(!this.enabled){
            return;
        }
        newsfeedAction({
            type: eventConstants.TIMER_START,
            timerName: "newsfeed_feed",
            timerInterval: loginState.config.ticker_refresh * 1000,
            secondCall:{
                callType: eventConstants.TIMER_API,
                success: eventConstants.UPDATE_NEWS_CONTENT,
                fail: eventConstants.API_LIGHT_FAIL,
                url: loginState.config.ticker + loginState.loginAuth
            }
        });

        newsfeedAction({
            type: eventConstants.TIMER_START,
            timerName: "newsfeed_clock",
            timerInterval: 60000,
            secondCall:{
                callType: eventConstants.EXECUTE_EVENT,
                success: eventConstants.UPDATE_NEWS_FEED_CLOCK
            }
        });
    }

    componentWillUnmount() {
        let {newsfeedAction} = this.props;
      
        clearTimeout(this.animTimeOut);
        newsfeedAction({
            type: eventConstants.TIMER_STOP,
            timerName: "newsfeed_feed"
        });

        newsfeedAction({
            type: eventConstants.TIMER_STOP,
            timerName: "newsfeed_clock"
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.animateTicker()
    }

    renderTicker() {
        let {loginState, newsFeedState} = this.props;
        let {newsfeedAction} = this.props;

        if (newsFeedState.config.mode === "CONTINUOUS") {
            return (
                <div ref={this.tickerWrapper}>
                     <span ref={this.myItem} className="inlineBlockDisplayContinuous">
                           {newsFeedState.items.map((item, i) =>
                               <span key={i}>{item.content}</span>
                           )}
                     </span>
                    <span ref={this.myItemCloned} className="inlineBlockDisplayContinuous">
                         {newsFeedState.items.map((item, i) =>
                             <span key={i}>{item.content}</span>
                         )}
                     </span>
                </div>
            )
        } else if (newsFeedState.config.mode === "CUSTOM") {
            return (
                <div ref={this.tickerWrapper}>
                    {newsFeedState.items.map((item, i) =>
                        <NewsFeedItem key={i} order={i} heading={this.heading}/>
                    )}
                </div>
            )
        }
    }


    animateTicker() {
        let {loginState, newsFeedState, newsfeedAction} = this.props;

        if(loginState.config.type === "ads") {
            if (loginState.config.ticker_pos === "disabled") {
                return;
            }
        }

        if(!this.enabled){
            return;
        }

        if(newsFeedState.permitStart === true){
            if (newsFeedState.config.mode === "CONTINUOUS") {
                let wrapperWidth = Math.round(this.tickerWrapper.current.offsetWidth);
                let listWidth = Math.round(this.myItem.current.offsetWidth);

                this.myTween = TweenMax.fromTo(this.tickerWrapper.current, newsFeedState.config.continuous_scroll.speed,
                    {x: (newsFeedState.config.continuous_scroll.directionFrom === "RIGHT") ? 0 : -2 * listWidth + wrapperWidth},
                    {
                        x: (newsFeedState.config.continuous_scroll.directionFrom === "RIGHT") ? -listWidth : 0,
                        ease: Linear.easeNone,
                        repeat: -1
                    }).play();

            } else if (newsFeedState.config.mode === "CUSTOM") {
                newsfeedAction({
                    type: eventConstants.START_NEWS_TICKER_ANIMS,
                    wrapperWidth:  Math.round(this.tickerWrapper.current.offsetWidth)
                });
            }
        }

    }

    clockClicked(e){
        if(e) e.preventDefault();

        this.clickTick++;
        if(!this.clickTimeout){
            this.clickTimeout = setTimeout(() => {
                if(this.clickTick >= 3){
                    let {popupLogoutAction} = this.props;
                    popupLogoutAction({
                        type: eventConstants.SHOW_POPUP_LOGOUT
                    });
                }
                this.clickTick = 0;
                this.clickTimeout = null;
            }, 700);
        }
    }

    renderClock() {
        let {clockState} = this.props;
        let extraClass = "time noselect";

        if (!isEmpty(this.props.skinSettings)) {
            if (this.props.skinSettings.clock.show === false) {
                return true;
            } else {
                EventBus.getInstance().fireEvent("ADD_CLASS_TO_MAIN_SECOND_STAGE", {
                    className: this.props.skinSettings.clock.cssClass.app
                });
                extraClass+= " " + this.props.skinSettings.clock.cssClass.clock;
            }
        }

        return (
            <div className={extraClass} onClick={(e) => this.clockClicked(e)}>
                <div className="time-content">{clockState.time}</div>
            </div>
        )
    }

    render() {
        let componentClass = "headingModified ";
        this.enabled = true;
        let {clockState, loginState} = this.props;

        if(loginState.config.ticker === null){
            this.enabled = false;
            return null;
        }

        if(!isEmpty(this.props.skinSettings)){
           if(this.props.skinSettings.show === false){
               this.enabled = false;
               return null;
           } else{
               EventBus.getInstance().fireEvent("ADD_CLASS_TO_MAIN_SECOND_STAGE", {
                   className: this.props.skinSettings.cssClass.app
               });

               componentClass+= this.props.skinSettings.cssClass.news + " ";
           }
        }

        if(loginState.config.type === "ads"){
            if(loginState.config.ticker_pos !== "disabled"){
                if(loginState.config.ticker_pos === "top"){
                    componentClass+= "common-color";
                } else if(loginState.config.ticker_pos === "bottom"){
                    componentClass+= "common-color bottom";
                }
            } else {
                return null;
            }
        }

        let firstCssClass = "";
        let secondCssClass = "";
        if (!isEmpty(this.props.firstCssClass)) {
            firstCssClass += " " + this.props.firstCssClass;
        }
        if (!isEmpty(this.props.secondCssClass)) {
            secondCssClass += " " + this.props.secondCssClass;
        }

        return (
            <div className={componentClass}>
                <div className={"heading-content" + firstCssClass} ref={this.headingContent}>
                    <div className={"heading" + secondCssClass} ref={this.heading}>
                        <div className="infinite-news">
                            <div className="infinite-news-content">
                                {this.renderTicker()}
                            </div>
                        </div>
                        {this.renderClock()}
                    </div>
                </div>
            </div>

        );
    }
}


const mapStateToProps = (state) => ({
    newsFeedState: state.newsFeedState,
    loginState: state.loginState,
    clockState: state.clockState
});

const mapDispatchToProps =  {
    newsfeedAction,
    popupLogoutAction
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsFeed);
import '../../assets/css/ad/ad.css';
import '../../assets/css/rating/rating-landscape.css';
import React, {Component, Fragment} from 'react';
import { connect} from "react-redux";
import {eventConstants} from "../../constants";
import NewsFeed from "../newsfeed/NewsFeed";
import MediaItem from "./MediaItem";
import OperatorCounterCall from "./OperatorCounterCall";
import Rate from "./Rate";
import "../../actions/adAction"
import adAction from "../../actions/adAction";
import PopupLogout from "../Popup/PopupLogout";
import Queue from "../queue/Queue";

class Ad extends Component {

    constructor(props){
        super(props);
        this.interval = null;
        this.intervalRating = null;
        this.hideTimeout = null;
        this.ratingTween = null;
        this.firstImage = React.createRef();
        this.imgFader = React.createRef();
        this.smartQRating = React.createRef();
        this.callingCounter = React.createRef();
    }

    componentDidMount() {
        let {loginState, adAction} = this.props;

        adAction({
            type: eventConstants.TIMER_START,
            timerName: "ads_feed",
            timerInterval: loginState.config.ads_refresh * 1000,
            secondCall:{
                callType: eventConstants.TIMER_API,
                success: eventConstants.AD_SERVER_CALL_SUCCESS,
                fail: eventConstants.API_LIGHT_FAIL,
                url: loginState.config.ads_feed + loginState.loginAuth,
            }
        });
    }

    componentWillUnmount() {
        let {adAction} = this.props;
        adAction({
            type: eventConstants.TIMER_STOP,
            timerName: "ads_feed"
        });
    }

    renderImgs(){
        let {loginState, adStateDetect} = this.props;

        if(adStateDetect.adItemsAr.length > 0){
            return(
                <Fragment>
                    {adStateDetect.adItemsAr.map((item, key) =>
                        <MediaItem key={key}
                                   idx={key}
                                   item={item}
                                   current={adStateDetect.current}
                                   previous={adStateDetect.previous}
                                   totalItems={adStateDetect.adItemsAr.length}/>
                    )}
                </Fragment>
            )
        }
        return null;
    }

    render() {
        let {loginState} = this.props;

        return (
            <Fragment>
                {loginState.config.type === "queue" ? <Queue /> : null}

                <div className="smartq-img-fader" ref={this.imgFader}>
                    {this.renderImgs()}
                </div>

                <NewsFeed/>

                {loginState.config.subtype === "rate" ? <Rate/> : null}

                {loginState.config.subtype === "counter" ? <OperatorCounterCall/> : null}

                <PopupLogout/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    adStateDetect: state.adStateDetect,
    loginState: state.loginState
});

const mapDispatchToProps =  {
    adAction
};


export default connect(mapStateToProps, mapDispatchToProps)(Ad);
/*
npm start

npm install



 */
import React, {Component, Fragment} from 'react';
import Login from './components/login/Login';
import { connect } from "react-redux";
import {eventConstants, globalConstants} from "./constants";
import Ticket from "./components/ticket/Ticket";
import FormHeading from "./components/login/subc/FormHeading";
import FormBottom from "./components/login/subc/FormBottom";
import NewsFeed from "./components/newsfeed/NewsFeed";
import Operator from "./components/operator/Operator";
import TopArea from "./components/operator/TopArea";
import PopupOperator from "./components/Popup/PopupOperator";
import Ad from "./components/ad/Ad";
import {isEmpty} from "./constants/utils";
import Queue from "./components/queue/Queue";
import SwipeableViews from 'react-swipeable-views';
import EventBus from "./constants/EventBus";
import popupLogoutAction from "./actions/popupLogout.action";
import loginAction from "./actions/login.action"; // https://react-swipeable-views.com/api/api/


class App extends Component {
    constructor(props) {

        super(props);

        this.mainAppRef = React.createRef();
        this.firstLevelDom = React.createRef();

        this.clickTick = 0;
        this.clickTimeout = null;

        EventBus.getInstance().addListener("ADD_CLASS_TO_MAIN_SECOND_STAGE", this.listener = data => {
            App.addClassToElement(this.firstLevelDom.current, data.className);
        });

        EventBus.getInstance().addListener("REMOVE_CLASS_FROM_MAIN_SECOND_STAGE", this.listener = data => {
            App.removeClassFromElement(this.firstLevelDom.current, data.className);
        });
    }

    static addClassToElement(pElem, pClass) {
        if (!isEmpty(pClass)) {
            let myCssSplit = pClass.split(" ");
            for (let idx = 0; idx < myCssSplit.length; idx++) {
                if (!pElem.classList.contains(myCssSplit[idx])) {
                    if (!isEmpty(myCssSplit[idx])) {
                        pElem.classList.add(myCssSplit[idx]);
                    }
                }
            }
        }
    }

    static removeClassFromElement(pElem, pClass) {
        if (!isEmpty(pClass)) {
            let myCssSplit = pClass.split(" ");
            for (let idx = 0; idx < myCssSplit.length; idx++) {
                if (!isEmpty(myCssSplit[idx])) {
                    if (pElem.classList.contains(myCssSplit[idx])) {
                        pElem.classList.remove(myCssSplit[idx]);
                    }
                }
            }
        }
    }

    getMainCssClass(){
        let {loginState, loginAction} = this.props;

        let composedClass = "App";
        if(loginState.loggedIn === true){
            if(!isEmpty(loginState.config.cssClass)){
                composedClass += " " + loginState.config.cssClass;
            }


            // emanuel setting external css string
            if(loginState.externalCssLoaded === false){
                loginAction({
                    type: globalConstants.API,
                    success: eventConstants.SUCCESS_CUSTOM_CSS,
                    fail: eventConstants.FAIL_DETECT,
                    url: loginState.visual.css.link + loginState.loginAuth
                });
            } else{
                if(this.firstLevelDom.current){
                   // this.firstLevelDom.current.setAttribute("style", loginState.externalCssContent);
                    const $style = document.createElement("style");
                    $style.setAttribute("id", "customExternalCss");
                    document.head.appendChild($style);
                    $style.innerHTML = loginState.externalCssContent;
                }
            }
        }

        this.treatDisableAnims();


        if (globalConstants.DISABLE_ANIMS === true) {
            composedClass += " disable-anims";
        }

        return composedClass ;
    }

    treatDisableAnims(){

        let {loginState} = this.props;
        if (loginState.screen === eventConstants.LAUNCH_OPERATOR) {
            if (loginState.config.switch !== null) {
                if (loginState.config.switch === "ticket") {
                    globalConstants.DISABLE_ANIMS = true;
                }
            } else{
                globalConstants.DISABLE_ANIMS = false;
            }
        } else{
            globalConstants.DISABLE_ANIMS = false;
        }

        // emanuel
        // disabled din cauza ca operator si switch ticket
        // if kkt procesor keep true sau force true else pastrat

    }

    renderScreen() {
        let {loginState} = this.props;

        try {
            console.log(loginState);
        }catch (e) {

        }

        if(loginState.loggedIn === true){
            let myHtml = document.querySelector("html");
            myHtml.setAttribute("dir", loginState.language[loginState.currentLang].dir);

            let classList = myHtml.classList;
            while (classList.length > 0) {
                classList.remove(classList.item(0));
            }
            myHtml.classList.add(loginState.language[loginState.currentLang].cssClass);
        }


        if(loginState.screen === eventConstants.LAUNCH_OPERATOR){
            if(loginState.config.switch !== null){
                if (loginState.config.switch === "ticket") {
                    return (
                        <Fragment>


                            <SwipeableViews
                                enableMouseEvents

                                onChangeIndex={(index, indexLatest, meta)=>{
                                    console.log("index: ", index);
                                    console.log("indexLatest: ", indexLatest);
                                    console.log("meta: ", meta);
                                }}
                            >

                                            <div className="operatorPanel">
                                                <TopArea/>
                                                <Operator/>
                                                <PopupOperator/>
                                            </div>

                                            <div className="ticketPanel">
                                                <NewsFeed/>
                                                <Ticket/>
                                            </div>

                            </SwipeableViews>
                        </Fragment>
                    )
                }
            }
        }

        switch (loginState.screen) {
            case eventConstants.LAUNCH_LOGIN:
                try {
                    let myCustomCss = document.getElementById("customExternalCss");
                    myCustomCss.remove();
                }catch (e) {
                    
                }
                return (
                    <Fragment>
                        <FormHeading/>
                        <Login/>
                        <FormBottom />
                    </Fragment>
                );

            case eventConstants.LAUNCH_SCREEN_TICKET:
                return (
                    <Fragment>
                        <NewsFeed />
                        <Ticket />
                    </Fragment>
                );

            case eventConstants.LAUNCH_OPERATOR:
                return (
                    <Fragment>
                        <TopArea />
                        <Operator />
                        <PopupOperator />
                    </Fragment>
                );

            case eventConstants.LAUNCH_ADS:
                return (
                    <Fragment>
                        <Ad />
                    </Fragment>
                );
            case eventConstants.LAUNCH_QUEUE:
                return (
                   <Queue />
                );
            default:
                return null;
        }
    }

    appClicked(e){
        if(e) e.preventDefault();

        this.clickTick++;
        if(!this.clickTimeout){
            this.clickTimeout = setTimeout(() => {
                if(this.clickTick >= 3){
                    let {popupLogoutAction} = this.props;
                    popupLogoutAction({
                        type: eventConstants.SHOW_POPUP_LOGOUT
                    });
                }
                this.clickTick = 0;
                this.clickTimeout = null;
            }, 700);
        }
    }

    render() {
        let {loginState} = this.props;
        let extraClass = "smartq-app ";

        /*if (loginState.screen === eventConstants.LAUNCH_QUEUE) {
            if(loginState.)
            extraClass = " with-ads top-ticker left-time-ticker";
        }*/

        if(loginState.screen === eventConstants.LAUNCH_QUEUE ||
            (loginState.screen === eventConstants.LAUNCH_ADS && loginState.config.subtype !== "rate") ){
            return (
                <div className={this.getMainCssClass()} ref={this.firstLevelDom}>
                    <Fragment >
                        <div onClick={(e) => this.appClicked(e)} className="smartq-app-content">
                            {this.renderScreen()}
                        </div>

                    </Fragment>
                </div>
            );
        } else{
            return (
                <div className={this.getMainCssClass()} ref={this.firstLevelDom}>
                    <Fragment >
                        <div className={extraClass}>
                            {this.renderScreen()}
                        </div>

                    </Fragment>
                </div>
            );
        }

    }
}

const mapStateToProps= (state) => ({
    loginState: state.loginState
});

const mapDispatchToProps =  {
    popupLogoutAction,
    loginAction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

window.addEventListener('load', function() {
	if (window.parent){
		window.parent.postMessage({ "action"	: "loaded" } , "*");
	}
});


console.log = function() {}
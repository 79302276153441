export let globalData = {
    API_BASE: "",
    CURRENT_LANG: "en"
};

export let globalServerData = {
    request:{ },
    auth:{ },
    config:{ },
    response:{ },
    language:{ }
};

export let getText = pText => {
    return globalServerData.language[globalData.CURRENT_LANG].texts[pText];
};

export const globalConstants = {
    INSTANT_LOGIN: true,

    DEV_ENABLE_LOG: true,
    DISABLE_ANIMS: false,

    INFO: 'INFO',
    EXECUTE_EVENT: 'EXECUTE_EVENT',

    VALIDATE_SEND_FORM: 'ACTION_VALIDATE_SEND_FORM',
    CHANGE_LANG: 'ACTION_CHANGE_LANG',

    LANG_EN: "en",
    LAND_AR: "ar",

    API: "API",
    LOCAL_STORAGE_OBJ: "LOCAL_STORAGE_OBJ",
    API_SECURE_PROTOCOL: "https://",
    API_UNSECURE_PROTOCOL: "http://",
    API_DEFAULT_URL: "smartq.stempora.me",
    API_URL: "/smartq/json/",
    API_PING: "ping",
    API_DETECT: "detect",

    LAST_LOGIN_SUCCESS: 'LAST_LOGIN_SUCCESS',
    LOCAL_STORAGE_OBJ_PING: "CTP_DATA_PING",
    LOCAL_STORAGE_OBJ_USER: "CTP_DATA_USER",
    LOCAL_STORAGE_OBJ_PASS: "CTP_DATA_PASS",

    SERVER_REQUEST_PROTOCOL_GET: "GET",
    SERVER_REQUEST_PROTOCOL_POST: "POST",
    SERVER_REQUEST_PROTOCOL: "POST",

    OPERATOR_WAITING: "OPERATOR_WAITING",
    OPERATOR_OPEN: "OPERATOR_OPEN",

    DEVICE_ID: "DEVICE_ID"


};

export const serverFields = {
    OK: "ok",
    ERROR: "error",
    PING: "ping",
    AUTH: "auth",
    AUTH_DEVICE: "deivce"
};

export const identifyScreen = {
    ticket: "LAUNCH_SCREEN_TICKET",
    operator: "LAUNCH_OPERATOR",
    ads: "LAUNCH_ADS",
    queue: "LAUNCH_QUEUE"
};

export const eventConstants = {
    TIMER_START: "TIMER_START",
    TIMER_STOP: "TIMER_STOP",
    TIMER_API: "TIMER_API",

    SUCCESS_CUSTOM_CSS: 'SUCCESS_CUSTOM_CSS',
    INPUT_FIELD_UPDATE_OPERATOR: 'INPUT_FIELD_UPDATE_OPERATOR',
    UPDATE_QUEUE_DATA_EMPTY: 'UPDATE_QUEUE_DATA_EMPTY',
    HIDE_NOTE_ACTION: 'HIDE_NOTE_ACTION',
    NOTE_ACTION_SUCCESS: 'NOTE_ACTION_SUCCESS',
    HIDE_LOGIN_ERR_POPUP: 'HIDE_LOGIN_ERR_POPUP',
    HIDE_LOGIN_POPUP: 'HIDE_LOGIN_POPUP',
    SHOW_EXIT_CONFIRMATION: 'SHOW_EXIT_CONFIRMATION',
    UPDATE_NEWS_FEED_CLOCK: 'UPDATE_NEWS_FEED_CLOCK',
    POPUP_OPEN_PRINT_TICKET: 'POPUP_OPEN_PRINT_TICKET',
    POPUP_OPEN_PRINT_TICKET_BACK: 'POPUP_OPEN_PRINT_TICKET_BACK',
    POPUP_OPEN_PRINT_TICKET_DONE: 'POPUP_OPEN_PRINT_TICKET_DONE',
    POPUP_OPEN_PRINT_TICKET_ENTRY_TEXT: 'POPUP_OPEN_PRINT_TICKET_ENTRY_TEXT',
    POPUP_PRINT_TICKET_DONE: 'POPUP_PRINT_TICKET_DONE',

    SWITCH_TRIGGERED: 'SWITCH_TRIGGERED',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

    QUEUE_SKIN_SUCCESS: 'QUEUE_SKIN_SUCCESS',

    SUCCESS_TICKET_INFO: 'SUCCESS_TICKET_INFO',
    HIDE_RATING_SCREEN: 'HIDE_RATING_SCREEN',

    SHOW_RATING_SCREEN: 'SHOW_RATING_SCREEN',

    UPDATE_JOBS_CONTENT: 'UPDATE_JOBS_CONTENT',
    SUCCESS_PRINT: 'SUCCESS_PRINT',

    SHOW_POPUP_LOGOUT: 'SHOW_POPUP_LOGOUT',

    AD_TICKET_CALL_SUCCESS: 'AD_TICKET_CALL_SUCCESS',


    API_LIGHT_FAIL: "API_LIGHT_FAIL",
    API_CRITICAL_FAIL: "API_CRITICAL_FAIL",

    LOGOUT_INIT: "LOGOUT_INIT",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    PIN_SUCCESS: "PIN_SUCCESS",
    PIN_NEW_CHAR_INPUT: "PIN_NEW_CHAR_INPUT",


    AD_SERVER_CALL_SUCCESS: "AD_SERVER_CALL_SUCCESS",
    QUEUE_REFRESH_SUCCESS: "QUEUE_REFRESH_SUCCESS",
    UPDATE_QUEUE_DATA: "UPDATE_QUEUE_DATA",
    EXECUTE_NEXT_QUEUE_ACTION: "EXECUTE_NEXT_QUEUE_ACTION",
    SWITCH_TO_ACTION_MODE: "SWITCH_TO_ACTION_MODE",

    AD_RATING_DATA_CALL: "AD_RATING_DATA_CALL",
    AD_RATING_DATA_CALL_SUCCESS: "AD_RATING_DATA_CALL_SUCCESS",

    AD_RATING_TICKET_INFO_CALL: "AD_RATING_TICKET_INFO_CALL",
    AD_RATING_DATA_TICKET_INFO_SUCCESS: "AD_RATING_DATA_TICKET_INFO_SUCCESS",

    RATE_NOW: "RATE_NOW",
    RATE_NOW_SUCCESS: "RATE_NOW_SUCCESS",

    CALL_RECALL_EXECUTE: "CALL_RECALL_EXECUTE",
    CALL_RECALL_EXECUTE_SUCCESS: "CALL_RECALL_EXECUTE_SUCCESS",

    SHOW_NEXT_MEDIA_ITEM: "SHOW_NEXT_MEDIA_ITEM",

    LAUNCH_SETUP: "LAUNCH_SETUP",
    LAUNCH_LOGIN: "LAUNCH_LOGIN",
    LAUNCH_SCREEN_TICKET: "LAUNCH_SCREEN_TICKET",
    LAUNCH_OPERATOR: "LAUNCH_OPERATOR",
    LAUNCH_ADS: "LAUNCH_ADS",
    LAUNCH_QUEUE: "LAUNCH_QUEUE",

    NEXT_ACTION_OPERATOR: "NEXT_ACTION_OPERATOR",
    NEXT_ACTION_SUCCESS: "NEXT_ACTION_SUCCESS",

    DROP_BUTTON_ACTION_OPERATOR: "DROP_BUTTON_ACTION _OPERATOR",
    DROP_BUTTON_ACTION_SUCCESS: "DROP_BUTTON_ACTION_SUCCESS",

    TAKE_BUTTON_ACTION_OPERATOR: "TAKE_BUTTON_ACTION_OPERATOR",
    TAKE_BUTTON_ACTION_SUCCESS: "TAKE_BUTTON_ACTION_SUCCESS",

    FINISH_BUTTON_ACTION_OPERATOR: "FINISH_BUTTON_ACTION_OPERATOR",
    FINISH_BUTTON_ACTION_SUCCESS: "FINISH_BUTTON_ACTION_SUCCESS",

    POSTPONE_BUTTON_ACTION_OPERATOR: "POSTPONE_BUTTON_ACTION_OPERATOR",
    POSTPONE_BUTTON_ACTION_SUCCESS: "POSTPONE_BUTTON_ACTION_SUCCESS",

    TRANSFER_BUTTON_ACTION_OPERATOR_POPUP: "TRANSFER_BUTTON_ACTION_OPERATOR_POPUP",
    TRANSFER_BUTTON_ACTION_SUCCESS_POPUP: "TRANSFER_BUTTON_ACTION_SUCCESS_POPUP",

    TRANSFER_BUTTON_ACTION_OPERATOR: "TRANSFER_BUTTON_ACTION_OPERATOR",
    TRANSFER_BUTTON_ACTION_SUCCESS: "TRANSFER_BUTTON_ACTION_SUCCESS",


    START_BREAK_ACTION_SUCCESS: "START_BREAK_ACTION_SUCCESS",

    RESUME_BREAK_ACTION_SUCCESS: "RESUME_BREAK_ACTION_SUCCESS",

    OPERATOR_SERVER_CALL:"OPERATOR_SERVER_CALL",

    RECALL_BUTTON_ACTION_OPERATOR: "RECALL_BUTTON_ACTION_OPERATOR",
    RECALL_BUTTON_ACTION_SUCCESS: "RECALL_BUTTON_ACTION_SUCCESS",

    ENABLE_RECALL: "ENABLE_RECALL",


    GET_TIME: "GET_TIME",

    START_NEWS_TICKER_ANIMS: "START_NEWS_TICKER_ANIMS",

    NEWS_TICKER_ANIM_COMPLETE: "NEWS_TICKER_ANIM_COMPLETE",

    UPDATE_NEWS_CONTENT: "UPDATE_NEWS_CONTENT",
    UPDATE_OPERATOR_CONTENT: "UPDATE_OPERATOR_CONTENT",

    UPDATE_TICKET_CONTENT: "UPDATE_TICKET_CONTENT",

    TICKET_PRINT_SCREEN_SHOW: "TICKET_PRINT_SCREEN_SHOW",
    TICKET_PRINT_SCREEN_PRINTING: "TICKET_PRINT_SCREEN_PRINTING",

    SELECT_TICKET_BUTTON: "SELECT_TICKET_BUTTON",

    POPUP_OPEN_SELECT_TICKET: "POPUP_OPEN_SELECT_TICKET",
    POPUP_OPEN_SELECT_TICKET_ITEM: "POPUP_OPEN_SELECT_TICKET_ITEM",
    POPUP_OPEN_SELECT_TICKET_BACK: "POPUP_OPEN_SELECT_TICKET_BACK",
    POPUP_OPEN_SELECT_TICKET_DONE: "POPUP_OPEN_SELECT_TICKET_DONE",

    PRINTING_TICKET_UPDATE_INFO: "PRINTING_TICKET_UPDATE_INFO",
    TICKET_PRINT_STATUS_RESET: "TICKET_PRINT_STATUS_RESET",
    DONE_SHOWING_PRINT_SCREEN: "DONE_SHOWING_PRINT_SCREEN",


    SUCCESS: "SUCCESS",

    SUCCESS_PING: "SUCCESS_PING",
    FAIL_PING: "FAIL_PING",

    SUCCESS_BLANK_DETECT: "SUCCESS_BLANK_DETECT",
    SUCCESS_DETECT: "SUCCESS_DETECT",
    FAIL_DETECT: "FAIL_DETECT",

    STORE: "STORE",
    SAVE_TO_LOCAL_STORAGE: "SAVE_TO_LOCAL_STORAGE",
    FORM_CHANGE: "FORM_CHANGE",
    LAUNCH_DETECT: "LAUNCH_DETECT",

    POPUP_ACTION_EXIT_SUCCESS: "POPUP_ACTION_EXIT_SUCCESS",

    OPERATOR_ACTION: "OPERATOR_ACTION"
};

export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    INFO: 'ALERT_INFO',
    CLEAR: 'ALERT_CLEAR',


    ERROR_USER: "Please insert user",
    ERROR_EMAIL: "Email not valid",
    ERROR_PASS: "Please insert password",

    INFO_LOGGINGIN: "Logging in ...",
    SUCCESS_LOGGEDIN: "Success, logged in.",
    ERROR_LOGGEDIN: "Failed to log in."
};


export const buttonsConstants = {
    BUTTON_EVENT: "BUTTON_EVENT",

    NEXT_BUTTON: 'NEXT_BUTTON',

    POSTPONED_BUTTON: 'POSTPONED_BUTTON',
    DROPPED_BUTTON: 'DROPPED_BUTTON',
    MANUAL_BUTTON: 'MANUAL_BUTTON',


    TAKE_BUTTON: 'TAKE_BUTTON',
    TRANSFER_BUTTON: 'TRANSFER_BUTTON',
    DROP_BUTTON: 'DROP_BUTTON',
    RECALL_BUTTON: 'RECALL_BUTTON',
    POSTPONE_BUTTON: 'POSTPONE_BUTTON',
    FINISH_BUTTON: 'FINISH_BUTTON',

};

export const operatorPopupConstants = {
    POPUP_ERROR: "POPUP_ERROR",

    TRANSFER_TAB_SWITCH: "TRANSFER_TAB_SWITCH",
    TRANSFER_ITEM_SELECTED: "TRANSFER_ITEM_SELECTED",

    SHOW_SETTINGS_POPUP: "SHOW_SETTINGS_POPUP",

    POPUP_ACTION_CONFIRM: "POPUP_ACTION_CONFIRM",
    POPUP_ACTION_CANCEL: "POPUP_ACTION_CANCEL",

    POPUP_TYPE_CONFIRMATION: "POPUP_TYPE_CONFIRMATION",
    POPUP_TYPE_TRANSFER: "POPUP_TYPE_TRANSFER",

    POPUP_NEXT: "POPUP_NEXT",
    POPUP_TAKE: "POPUP_TAKE",
    POPUP_DROP: "POPUP_DROP",
    POPUP_POSTPONE: "POPUP_POSTPONE",
    POPUP_FINISH: "POPUP_FINISH",
    POPUP_TRANSFER: "POPUP_TRANSFER",

    POPUP_ON_BREAK: "POPUP_ON_BREAK",
    POPUP_LOGOUT: "POPUP_LOGOUT",
    POPUP_BREAK: "POPUP_BREAK",
    POPUP_RESUME: "POPUP_RESUME",
    POPUP_RECALL: "POPUP_RECALL",

    POPUP_POSTPONED_BUTTON: "POPUP_POSTPONED_BUTTON",
    POPUP_DROPPED_BUTTON: "POPUP_DROPPED_BUTTON",
    POPUP_MANUAL_BUTTON: "POPUP_MANUAL_BUTTON",


    POPUP_PIN: "POPUP_PIN",

    POPUP_ACTION_EXIT: "POPUP_ACTION_EXIT"

};
